<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
          <el-form
        ref="search"
        :inline="true" class="searchBoxForm" :model="paramsData">
        <el-form-item label="时间段" prop="equipmentStatus" >
          <el-date-picker
            @change="search1"
            size="mini"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡检区域" prop="equipmentStatus" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="pageParams.networkId"
            placeholder="请选择巡检区域"
            
          >
          <!-- @change="searchList" -->
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
                   :label="item.networkName"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="pageParams.userId"
            placeholder="请选择巡线员"
            
          >
          <!-- @change="searchList" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态" prop="equipmentStatus" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="pageParams.taskGenerateStatus"
            placeholder="请选择状态"
            
          >
          <!-- @change="searchList" -->
            <el-option
              v-for="item in optionsL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
          size="mini"
            placeholder="请输入任务编号"
            v-model="pageParams.searchText"
            style="width:170px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          size="mini"
          type="primary"
          @click="searchList"
          style=""
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        ><el-button
          size="mini"
          type="primary"
          @click="exportDetil"
          v-if="$anthButtons.getButtonAuth('deriveEquipmenttask')"
          >导出</el-button
        >
         </el-form-item>
      </el-form>
      </div>
    </div>
    <el-divider style="color: #f2f2f2"></el-divider>
    <div >
      <!-- <div class="table-height"> -->
     
      <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            class="table-height-in"
            :stripe="true"
            height="calc(100vh - 424px)"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign:'center',
              background: '#f8f8f9',
            }"
          >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="left"
          width="50"
        >
        </el-table-column>
        <el-table-column
            prop="taskCode"
            label="任务编号"
            align="left"
            show-overflow-tooltip
            width="170"
          >
        </el-table-column>
        <el-table-column
            prop="networkName"
            label="巡检区域"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="eqTypeName"
            label="巡检设备"
            align="left"
            show-overflow-tooltip
            
          >
        </el-table-column>
        <el-table-column
            prop="checkingPoint"
            label="巡检点数"
            align="left"
            show-overflow-tooltip
            width="80"
          >
        </el-table-column>
         <el-table-column
            prop="finishPush"
            label="已完成数"
            align="left"
            show-overflow-tooltip
            width="100"
          >
          <template  slot-scope="{ row }">
            <span @click="showList(row.id,row.taskGenerateStatus )" style="color:#409EFF">{{row.finishPush}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="dayNumber"
            label="巡检周期"
            align="left"
            show-overflow-tooltip
             width="100"
          >
          <template  slot-scope="{ row }">
            <span v-if='row.checkPeriod == 1'>{{row.dayNumber}}天一周期</span>
            <span v-if='row.checkPeriod == 2'>一月三周期</span>
            <span v-if='row.checkPeriod == 3'>一月二周期</span>
            <span v-if='row.checkPeriod == 4'>一月一周期</span>
            <span v-if='row.checkPeriod == 5'>一季度一周期</span>
          </template>
        </el-table-column>
         <el-table-column
            prop="executorPeopleName"
            label="巡线员"
            align="left"
             width="190"
            show-overflow-tooltip
          >
        </el-table-column>
         
         <el-table-column
            prop="used"
            label="执行时间"
            align="left"
            width="200"
            show-overflow-tooltip
          >
          <template slot-scope="{ row }">
            <div v-if="row.isRedeploy != 3">
            {{String(row.taskDurationStartTime).replaceAll("-", "/")}} - {{String(row.taskDurationEndTime).replaceAll("-", "/")}}
            </div>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="used"
            label="任务状态"
            align="left"
            width="90"
            show-overflow-tooltip
          >
          <template slot-scope="{ row }">
            <el-tag v-if="row.taskStatusName == '未开始' && row.isRedeploy != 3" size="mini" style="color: #F4C41D;background: #FFFCF6;border: 1px solid #F0DCA8;">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag> 
          <el-tag v-else-if="row.taskStatusName == '已完成'" size="mini" type='success'>
              {{ row.taskStatusName }}
          </el-tag>
          <el-tag v-else-if="row.isRedeploy == 3" size="mini" >
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag> 
           <el-tag v-else :type='taskStatus(row.taskStatusName)' size="mini">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag>  
          <!-- <el-tag v-if="row.taskStatusName == '未开始'" style="color: #F4C41D;border: 1px solid #F0DCA8;background: #FFFCF6;">
              {{ row.taskStatusName }}
          </el-tag>  -->
          <!-- <el-tag v-if="row.taskStatusName != '进行中' && row.isRedeploy == 3" size="mini">
              已转派
          </el-tag>
          <el-tag v-else-if="(row.taskStatusName == '未开始') && row.isRedeploy != 3" size="mini" style="color: #F4C41D;border: 1px solid #F0DCA8;background: #FFFCF6;">
              {{ row.taskStatusName }}
          </el-tag> 
          <el-tag v-else-if="(row.taskStatusName == '已完成' && row.isRedeploy != 1)"  size="mini">
              {{ row.isRedeploy == 3?'已转派':row.taskStatusName }}
          </el-tag>  -->
          <!-- 进行中 全部转派 -->
          <!-- <el-tag v-else-if="(row.taskStatusName == '进行中' && row.isRedeploy == 3)"  size="mini" stype='success'>
              已完成
          </el-tag> 
          <el-tag v-else :type='taskStatus(row.taskStatusName)' size="mini">
              {{ row.taskStatusName}}
          </el-tag>   -->
        </template>
        </el-table-column>
        <el-table-column
            prop="taskRemark"
            label="备注"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        
        <el-table-column label="操作" align="left" width="160">
          <template slot-scope="{row}">
            <div class="operateTool">
              <el-button
                type="primary"
                size="mini"
                @click="IsOpenFun(4, row)"
                v-if="$anthButtons.getButtonAuth('remarkEquipmenttask')"
                >备注</el-button
              >
              <el-button type="primary" plain  
              v-if="(row.isRedeploy != 3 && row.taskStatusName != '未完成' && row.taskStatusName != '已完成') && $anthButtons.getButtonAuth('trunEquipmenttask')" 
              @click="handle(row)" size="mini"
                >转派</el-button
              >
              
              <!-- <el-button type="primary" plain v-if="row.taskGenerateStatus<2"  @click="addItem(row)" size="mini"
                >编辑</el-button>-->
            </div> 
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10,30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    </div>
    <!-- 备注 -->
    <el-dialog :visible.sync="remarkBool" width="460px" @close="CloseChangePlan(1)" :close-on-click-modal='false'>
       <el-form :model="remarkData"  ref="remarkData" label-width="80px" :rules="rules1">
        <el-form-item label="备注" prop="taskRemark">
          <el-input
            style="width: 100%"
            maxlength="200"
            type="textarea"
            name="limitLength"
            show-word-limit
            placeholder="请输入备注"
            v-model="remarkData.taskRemark"
          ></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="submitRemark" type="primary"  >确 认</el-button>
        <el-button size="small" @click="remarkBool = false"  >取 消</el-button>
      </span>
    </el-dialog>
    <!-- 转派 -->
    <el-dialog :title="titles" :visible.sync="redeployVisible" @closed="CloseChangePlan" width="460px" :close-on-click-modal='false'>
      <el-form :model="DeviceData"  ref="DeviceData" label-width="80px" :rules="rules">
        <el-form-item label="巡线员" prop="taskUser">
          <el-select style="width: 338px" v-model="DeviceData.taskUser">
            <el-option
              v-for="item in excutorSpecialArray"
              :key="item.id"
              :label="item.username+'('+item.mobile+')'"
              :value="item.id.toString()">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行时间" prop="taskDurationStartTime">
          <el-date-picker style="display:inline-block;width: 162px;" value-format="yyyy-MM-dd" v-model="DeviceData.taskDurationStartTime"  type="date" placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker style="display:inline-block;width: 162px;" readonly v-model="DeviceData.taskDurationEndTime" type="date" placeholder="结束日期"></el-date-picker>
        
        </el-form-item>
        <el-form-item label="备注" prop="taskRemark">
          <el-input v-model="DeviceData.taskRemark" 
            type="textarea" 
            style="width: 338px" 
            placeholder="请填写备注" 
            maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="redeployVisible = false" >取 消</el-button>
        <el-button size="small" @click="submitRedeploy" type="primary">确 认</el-button>
      </div>
    </el-dialog>
    <!-- 编辑 -->
     <!-- <el-dialog :title="titles" :visible.sync="redeployVisible" @close="CloseChangePlan" width="540px">
      <el-form :model="DeviceData"  style="margin:0 52px;" ref="DeviceData" label-width="80px" :rules="rules">
      <el-form-item label="巡检区域" prop="networkId" v-if="DeviceData.taskGenerateStatus==0||!DeviceData.taskGenerateStatus">
          <el-select style="width: 300px" v-model="DeviceData.networkId">
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
                   :label="item.network_name"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检设备" prop="eqType" v-if="DeviceData.taskGenerateStatus==0||!DeviceData.taskGenerateStatus">
          <el-select style="width: 300px" v-model="DeviceData.eqType">
            <el-option
              v-for="item in eqTypeArray"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员" prop="taskUser">
          <el-select style="width: 300px" v-model="DeviceData.taskUser">
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id.toString()">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行时间" prop="taskDurationStartTime">
          <el-date-picker v-if="DeviceData.taskGenerateStatus==0||!DeviceData.taskGenerateStatus"
            v-model="taskDurationStartTimeArr"
            type="daterange"
            @change="DatePickerFun"
            style="width: 300px"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <div v-else>
            <el-date-picker style="display:inline-block;width: 144px;" readonly value-format="yyyy-MM-dd" v-model="DeviceData.taskDurationStartTime"  type="date" placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker style="display:inline-block;width: 144px;"  v-model="DeviceData.taskDurationEndTime" type="date" placeholder="结束日期"></el-date-picker>
          </div>
          
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="redeployVisible = false" >取 消</el-button>
        <el-button size="small" @click="submitRedeploy" type="primary">确 认</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  getNetwork,
  getUserList,
  taskpage,
  getDict,
  updatetask,
  redeploy,
  getRemark,excelTask1
} from "../../RequestPort/maintenance/task";
import { exportMethod } from '../../common/js/exportExcel'
import {
  templatePageList,
  templateGeneralStatusToggle
} from "@/RequestPort/dict/cycle";
import {
  selectDictListByPid
} from "../../RequestPort/business/userFile.js";
import pageMixins from "@/utils/pageMixins";
export default {
  name:'Sample',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
        taskType:2
      }, //  分页查询参数
      tableData: [], //  表格数据
       total:0,
      title:'',
      dialogVisible:false,//启用弹框
      value:'',
      // 用户分类
      options:[],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ],
       // 片区字典，巡检区域
      selectDownArray: [],
      // 巡线人员
      excutorDownArray: [],
      //任务状态 0-未开始 1-进行中 2-部分完成3 已完成
        optionsL: [{
          value: '0',
          label: '未开始'
        }, {
          value: '1',
          label: '进行中'
        }, 
         {
          value: '2',
          label: '已完成'
        },{
          value: '3',
          label: '未完成'
        },{
          value: '4',
          label: '已转派'
        }],
        paramsData:{},
        remarkBool:false,
        remarkData:{taskRemark:'',id:''},
        rules: {
        networkId: [{ required: true, message: "请选择巡检区域", trigger: "change" }],
        eqType:[{ required: true, message: "请选择巡检设备", trigger: "change" }],
        taskUser: [{ required: true, message: "请选择巡线员", trigger: "change" }],
        taskDurationStartTime: [{ required: true, message: "请选执行日期", trigger: "change" }],
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ]
      },
      rules1: {
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ]
      },
      // 转派
      redeployVisible:false,
      // 转派人员
      excutorSpecialArray:[],
      DeviceData:{taskType:2,taskDurationStartTime:'',taskDurationEndTime:'',eqType:'',networkId:'',taskUser:'',taskRemark:''},
      eqTypeArray:[],
      taskDurationStartTimeArr:[],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        },
      },
      titles:'',
      DataPicker:[]
    };
  },
  mounted() {
    // 巡检区域字典，片区下拉
    getNetwork().then((e) => {
      this.selectDownArray = e.data;
    });
    // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    this.getList()
    selectDictListByPid({pid: 460}).then(res => {
      this.options = res.data
    })
    getDict({pid:720}).then(res=>{
      this.eqTypeArray = res.data[0].children
    })
  },
  methods: {
    
    /**
     * 转派
     */
    handle(row) {
      this.titles = '转派'
      this.DeviceData = {...this.DeviceData,...row,taskUser:''}
      // 获取巡线员
      getUserList({userId:row.taskUser,type:2}).then((e) => {
        this.excutorSpecialArray = e.data;
      });
      this.redeployVisible = !this.redeployVisible;
      if(this.$refs.DeviceData){
        this.$refs.DeviceData.resetFields()
      }
    },
    /**
     * 任务状态
     */
    taskStatus(start){
      if(start == '进行中') return 'warning' 
      if(start == '已转派') return '' 
      if(start == '已完成') return 'success' 
      if(start == '未完成') return 'danger' 
    },
    exportDetil(){
      excelTask1(this.pageParams).then(res=>{
        exportMethod(res, '周期任务')
      })
    },
    /**
     * 已完成点击事件
     */
    showList(id,taskGenerateStatus ){
      // this.$router.push({ path: '/home/device/details', query: { id: id,screcd:1 } })
      let chakan = this.$router.resolve({
					path: '/home/device/details',      //跳转目标窗口的地址
					query: { id: id,screcd:1,taskGenerateStatus  }
				})
				window.open(chakan.href);
    },
    resize(){
      this.pageParams ={
        current: 1,
        size: 100,
        taskType:2
      }
      this.getList()
      this.DataPicker = []
    },
    DatePickerFun(){
      if(this.taskDurationStartTimeArr.length>0){
        this.DeviceData.taskDurationStartTime=this.taskDurationStartTimeArr[0]
        this.DeviceData.taskDurationEndTime=this.taskDurationStartTimeArr[1]
      }else{
        this.DeviceData.taskDurationStartTime=''
        this.DeviceData.taskDurationEndTime=''
      }
    },
    addItem(row){
      if(!row){
        this.titles = '添加';
      }else{
        this.titles = '编辑';
        this.DeviceData.taskDurationStartTime = row.taskDurationStartTime;
        this.DeviceData.taskDurationEndTime = row.taskDurationEndTime
        this.DeviceData.eqType = row.eqType
        this.DeviceData.networkId = row.networkId
        this.DeviceData.taskUser = row.taskUser
        this.DeviceData.id = row.id
        this.DeviceData.taskGenerateStatus = row.taskGenerateStatus
        this.taskDurationStartTimeArr = [row.taskDurationStartTime,row.taskDurationEndTime]
        // console.log(row);
      }
      this.redeployVisible = true;
    },
    /**
     * 转派确认
     */
    submitRedeploy(){
      this.$refs["DeviceData"].validate((valid) => {
        if (valid) {
        // updatetask(this.DeviceData).then(res=>{
        //   this.$message.success(res.msg)
        //   this.getList(1)
        //   this.redeployVisible = false
        // })
        let data = {id:this.DeviceData.id,
        taskDurationEndTime:this.DeviceData.taskDurationEndTime,
        taskDurationStartTime:this.DeviceData.taskDurationStartTime,
        taskRemark:this.DeviceData.taskRemark,
        taskUser:this.DeviceData.taskUser}
          redeploy(data).then(res => {
              this.$message.success(res.msg)
          this.getList(1)
          this.redeployVisible = false
          })
        }
      })
    },
    CloseChangePlan(val){
      this.DeviceData = {}
      this.taskDurationStartTimeArr = []
      if(val){
        this.$refs["remarkData"].resetFields()
      } else {
        this.$refs["DeviceData"].resetFields()
      }
    },
    search1(){
      if(this.DataPicker&&this.DataPicker.length>0){
        this.pageParams.startTime = this.DataPicker[0]
        this.pageParams.endTime = this.DataPicker[1]
      }else{
       delete  this.pageParams.startTime
       delete  this.pageParams.endTime
      }
      this.pageParams.current = 1
      // this.getList()
    },
    /**
     * 备注弹窗
     */
    IsOpenFun(e, row) {
      this.remarkBool = true
      if(this.$refs.remarkData){
        this.$refs.remarkData.resetFields()
      }
      this.remarkData.id = row.id
      this.remarkData.taskRemark = row.taskRemark
    },
    /**
     * 备注提交
     */
    submitRemark(){
      this.$refs.remarkData.validate((valid) => {
        if (valid) {
          getRemark(this.remarkData).then(res => {
            this.$message.success("添加备注成功");
            this.getList();
            this.remarkBool = false
          })
        }
      })
    },
    /**
     * 获取列表
     */
    getList(val){
      if(val){
         this.pageParams.current = 1
      }
      taskpage(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 更改状态
     */
    stateUpdata(val){
      let that = this
      this.$confirm('是否启用此模板，启用后将自动停用该类型其他模板', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          templateGeneralStatusToggle({id:val}).then(res => {
            that.$message({
              type: 'success',
              message: res.data
            });
            that.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    /**
     * 搜索
     */
    searchList(val){
      this.getList(1)
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchBar{
  overflow: inherit;
.el-form-item{
  margin-bottom: 0;
}
}
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    height: 25px;}
//  .searchItem {
//       // margin-bottom: 1%;
//       //  margin-left: 1%;
//     }
// .info-item {
//   margin-bottom: 20px;
// }
// .label {
//   color: #909399;
// }
// .content-item {
//   color: #444444;
// }
// .SearchBar {
  /* border: 1px solid #ecedf1; */
  // border-radius: 5px;
  // margin: 10px;
  // padding-top: 1%;

  // background-color: #ffffff;
// }
.all {
  // background-color:#f3f4f8;
  height:auto
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 282px);
}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diaBtn{
  width: 100px;
  height: 40px;
  margin-bottom: 15px;
}
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
.el-pagination{
        margin: 15px 0px 15px;
  }
</style>